<template>
    <div class="row justify-content-center">
        <div class="col-sm-3">
            <b-card title="輸入區域">
                <b-dropdown id="dropdown-in0" v-on:shown="syncTags" text="匯入資料" class="m-md-2">
                    <b-dropdown-item @click="schoolId2UserData" v-if="inputArea.status === 'text'">由輸入框匯入</b-dropdown-item>
                    <b-dropdown-divider v-if="inputArea.status === 'text'"/>
                    <b-dropdown-item
                            v-for="tag in inputArea.tags"
                            v-bind:key="tag.id"
                            v-on:click="inputFormBackendData(tag.id)"
                    >
                        {{tag.name}}
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown id="dropdown-move0" text="交換" class="m-md-2">
                    <b-dropdown-item @click="switch_value(0,1)">工作區1</b-dropdown-item>
                    <b-dropdown-item @click="switch_value(0,2)">工作區2</b-dropdown-item>
                    <b-dropdown-item @click="switch_value(0,3)">結果</b-dropdown-item>
                </b-dropdown>
                <b-button class="m-md-2" @click="clean(0)">清空</b-button>
                <b-form-textarea
                        id="inputTextArea"
                        v-model="inputArea.text"
                        placeholder="輸入學號，一列一個"
                        rows="10"
                        max-rows="10"
                        v-if="inputArea.status === 'text'"
                />
                <b-form-select
                        v-model="inputArea.selected"
                        :options="inputArea.options"
                        :select-size="10"
                        v-if="inputArea.status === 'select'"
                />
            </b-card>
        </div>
        <div class="col-sm-3">
            <b-card title="工作區1">
                <b-dropdown id="dropdown-wa1" text="集合操作" class="m-md-2">
                    <b-dropdown-item @click="setAction('Intersection')">與2取交集</b-dropdown-item>
                    <b-dropdown-item @click="setAction('Union')">與2取聯集</b-dropdown-item>
                    <b-dropdown-item @click="setAction('Complement')">1＼2(差集)</b-dropdown-item>
                </b-dropdown>
                <b-dropdown id="dropdown-move1" text="交換" class="m-md-2">
                    <b-dropdown-item  @click="switch_value(1,2)">工作區2</b-dropdown-item>
                    <b-dropdown-item @click="switch_value(1,3)">結果</b-dropdown-item>
                </b-dropdown>
                <b-button class="m-md-2" @click="clean(1)">清空</b-button>
                <b-form-select
                        v-model="workArea1.selected"
                        :options="workArea1.options"
                        :select-size="10"
                />
            </b-card>
        </div>
        <div class="col-sm-3">
            <b-card title="工作區2">
                <b-dropdown id="dropdown-move2" text="交換" class="m-md-2">
                    <b-dropdown-item  @click="switch_value(2,1)">工作區1</b-dropdown-item>
                    <b-dropdown-item  @click="switch_value(2,3)">結果</b-dropdown-item>
                </b-dropdown>
                <b-button class="m-md-2" @click="clean(2)">清空</b-button>
                <b-form-select
                        v-model="workArea2.selected"
                        :options="workArea2.options"
                        :select-size="10"
                />
            </b-card>
        </div>
        <div class="col-sm-3">
            <b-card title="結果">
                <b-dropdown id="dropdown-move3" text="交換" class="m-md-2">
                    <b-dropdown-item @click="switch_value(3,1)">工作區1</b-dropdown-item>
                    <b-dropdown-item @click="switch_value(3,2)">工作區2</b-dropdown-item>
                </b-dropdown>
                <b-button class="m-md-2" @click="clean(3)">清空</b-button>
                <b-button class="m-md-2" @click="deleteSelected">刪除所選</b-button>
                <b-input-group :prepend="workArea3.options.length === 0? '清空Tag': '對Tag組'" class="m-md-2">
                    <b-form-input v-model="workArea3.tag_name" list="my-tags" type="text"/>
                    <datalist id="my-tags">
                        <option v-for="tags in inputArea.tags" v-show="!tags.system_tag" v-bind:key="tags.id">{{ tags.name }}</option>
                    </datalist>
                    <b-input-group-append>
                        <b-button variant="info" @click="uploadTag" v-bind:disabled="inputArea.running">{{workArea3.options.length === 0? '確定': '更新'}}</b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-form-select
                        v-model="workArea3.selected"
                        :options="workArea3.options"
                        :select-size="10"
                />
            </b-card>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "TagManagement",
        data: function () {
            return{
                workArea1: {
                    selected: "",
                    options: []
                },
                workArea2: {
                    selected: "",
                    options: []
                },
                workArea3: {
                    selected: "",
                    options: [],
                    tag_name: ""
                },
                inputArea: {
                    status: "text",
                    running: false,
                    text: "",
                    options: [],
                    selected: "",
                    tags: []
                }
            }
        },
        methods: {
            syncTags: function (){
                this.inputArea.running = true;
                axios.get("/api/tag/user_tags")
                    .then((response)=>{
                        this.inputArea.tags = response.data;
                        this.inputArea.running = false;
                    })
            },
            inputFormBackendData: function (tag_id) {
                this.inputArea.running = true;
                axios.get("/api/tag/user_tag/" + tag_id)
                .then((response)=>{
                    this.inputArea.options = response.data;
                    this.inputArea.running = false;
                });
                this.inputArea.status = "select";
                this.inputArea.text = "";
            },
            schoolId2UserData: function () {
                this.inputArea.running = true;
                axios.post('/api/tag/mapping_user',{
                    lists: this.inputArea.text
                }).then((response)=>{
                    this.inputArea.status = "select";
                    this.inputArea.text = "";
                    this.inputArea.running = false;
                    this.inputArea.options = response.data;
                })
            },
            clean: function (areaName) {
                switch (areaName) {
                    case 0:
                        this.inputArea = {
                            status: "text",
                            running: false,
                            text: "",
                            select: [],
                            selected: "",
                            tags: []
                        };
                        break;
                    case 1:
                        this.workArea1.options = [];
                        this.workArea1.selected = "";
                        break;
                    case 2:
                        this.workArea2.options = [];
                        this.workArea2.selected = "";
                        break;
                    case 3:
                        this.workArea3.options = [];
                        this.workArea3.selected = "";
                        break;
                }
            },
            switch_value: function (src, dst){
                let temp = [];
                switch (src) {
                    case 0:{
                        switch (dst) {
                            case 1:{
                                // 01
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea1.options;
                                this.workArea1.options = temp;
                                break;
                            }
                            case 2:{
                                // 02
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea2.options;
                                this.workArea2.options = temp;
                                break;
                            }
                            case 3:{
                                // 03
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                        }
                        break;
                    }
                    case 1:{
                        switch (dst) {
                            case 0:{
                                // 10
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea1.options;
                                this.workArea1.options = temp;
                                break;
                            }
                            case 2:{
                                // 12
                                temp = this.workArea1.options;
                                this.workArea1.options = this.workArea2.options;
                                this.workArea2.options = temp;
                                break;
                            }
                            case 3:{
                                // 13
                                temp = this.workArea1.options;
                                this.workArea1.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                        }
                        break;
                    }
                    case 2:{
                        switch (dst) {
                            case 0:{
                                // 03
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                            case 1:{
                                // 12
                                temp = this.workArea1.options;
                                this.workArea1.options = this.workArea2.options;
                                this.workArea2.options = temp;
                                break;
                            }
                            case 3:{
                                // 23
                                temp = this.workArea2.options;
                                this.workArea2.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                        }
                        break;
                    }
                    case 3:{
                        switch (dst) {
                            case 0:{
                                // 03
                                temp = this.inputArea.options;
                                this.inputArea.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                            case 1:{
                                // 13
                                temp = this.workArea1.options;
                                this.workArea1.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                            case 2:{
                                // 23
                                temp = this.workArea2.options;
                                this.workArea2.options = this.workArea3.options;
                                this.workArea3.options = temp;
                                break;
                            }
                        }
                        break;
                    }
                }
            },
            setAction: function (action) {
                let set = new Set();
                this.workArea3.options = [];
                switch (action) {
                    case "Union":
                        this.workArea3.options = this.workArea1.options.concat(this.workArea2.options).filter(item => !set.has(item.uuid)? set.add(item.uuid): false);
                        set.clear();
                        break;
                    case "Intersection":
                        // 交集
                        this.workArea2.options.forEach(item => set.add(item.uuid));
                        this.workArea1.options.forEach((item)=>{
                            if(set.has(item.uuid)){
                                this.workArea3.options.push(item)
                            }
                        });
                        break;
                    case "Complement":
                        // 差集
                        this.workArea2.options.forEach(item => set.add(item.uuid));
                        window.console.log(set);
                        this.workArea1.options.forEach((item)=>{
                            window.console.log(item.uuid);
                            window.console.log(item.uuid in set);
                            if(!(set.has(item.uuid))){
                                this.workArea3.options.push(item)
                            }
                        });
                        break;
                }
            },
            uploadTag: function () {
                this.inputArea.running = true;
                axios.post('/api/tag/user_tag',{
                    tag_name: this.workArea3.tag_name,
                    users: Array.from(this.workArea3.options, x => x.uuid)
                }).then(()=>{
                    this.inputArea.running = false;
                }).catch((error)=>{
                    if(error.response.status === 400){
                        window.alert(error.response.data.msg);
                    }else {
                        window.alert(error.message);
                    }
                })
            },
            deleteSelected: function () {
                this.workArea3.options = this.workArea3.options.filter(item=> item.uuid !== this.workArea3.selected)

            }
        },
        mounted: function () {
            this.syncTags();
        }
    }
</script>

<style scoped>

</style>